import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SortByColumn } from '../utils/sorting';

const styles = (theme) => ({
  table: {
    width: '100%',
  },
  headerCell: {
    cursor: 'pointer',
  },
  warnValue: {
    color: 'red',
  },
});

const OrdersTables = ({ classes, data }) => {
  const { t } = useTranslation();

  const [sortConfig, setSortConfig] = React.useState({
    key: null,
    direction: 'ascending',
  });

  const sortedItems = React.useMemo(
    () => SortByColumn(data, sortConfig),
    [data, sortConfig]
  );

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    if (sortConfig.key === key && sortConfig.direction === 'descending') {
      setSortConfig({ key: null, direction: 'ascending' });
      return;
    }
    setSortConfig({ key, direction });
  };

  const currency = data.length ? data[0].currency : '';
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('orderId')}
          >
            №
            {sortConfig.key === 'orderId'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('partNumber')}
          >
            {t('part_number')}
            {sortConfig.key === 'partNumber'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('brand')}
          >
            {t('brand')}
            {sortConfig.key === 'brand'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('delivery')}
          >
            {t('delivery')}
            {sortConfig.key === 'delivery'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('priceOrder')}
          >
            <div>{t('order_price')}</div>
            <div>{currency.trim()}</div>
            {sortConfig.key === 'priceOrder'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('priceSale')}
          >
            <div>{t('sale_price')}</div>
            <div>{currency.trim()}</div>
            {sortConfig.key === 'priceSale'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('ordered')}
          >
            {t('ordered')}
            {sortConfig.key === 'ordered'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('purchased')}
          >
            {t('purchased')}
            {sortConfig.key === 'purchased'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('accepted')}
          >
            {t('ready_to_ship')}
            {sortConfig.key === 'accepted'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('shipped')}
          >
            {t('shipped')}
            {sortConfig.key === 'shipped'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('refused')}
          >
            {t('refused')}
            {sortConfig.key === 'refused'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('yourReference')}
          >
            {t('reference')}
            {sortConfig.key === 'yourReference'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
          <TableCell
            className={classes.headerCell}
            onClick={() => requestSort('artName')}
          >
            {t('description')}
            {sortConfig.key === 'artName'
              ? sortConfig.direction === 'ascending'
                ? ' ↑'
                : ' ↓'
              : ''}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedItems.map((value) => (
          <TableRow key={value.id}>
            <TableCell>{value.orderId}</TableCell>
            <TableCell>
              <div>{value.partNumber}</div>
              <div className={classes.warnValue}>{value.substNumber}</div>
            </TableCell>
            <TableCell>
              <div>{value.brand}</div>
              <div className={classes.warnValue}>{value.substBrand}</div>
            </TableCell>
            <TableCell>
              {value.delivery} ({value.tariff})
            </TableCell>
            <TableCell>{value.priceOrder}</TableCell>
            <TableCell>{value.priceSale}</TableCell>
            <TableCell>{value.ordered}</TableCell>
            <TableCell>{value.purchased}</TableCell>
            <TableCell>{value.accepted}</TableCell>
            <TableCell>{value.shipped}</TableCell>
            <TableCell>{value.refused}</TableCell>
            <TableCell>{value.yourReference}</TableCell>
            <TableCell>{value.artName}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

OrdersTables.defaultProps = {
  data: [],
};

export default withStyles(styles)(OrdersTables);
