import React, { useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as orderApi from '../api/order-api';
import { getCookie } from '../utils/cookies';
import {
  Page,
  OrdersTable,
  ActionsGroup,
  SearchInputWithSelect,
} from '../components';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { saveExcel } from '../utils/excel';

const OrdersPage = ({ classes }) => {
  const { t } = useTranslation();
  const filterTypes = [
    { title: t('part_number'), value: 1 },
    { title: t('order_number'), value: 2 },
    { title: t('reference'), value: 3 },
  ];
  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [data, setData] = useState([]);
  const [filterType, setFilterType] = useState(filterTypes[0].value);
  const [filter, setFilter] = useState('');

  async function fetchData(query) {
    const result = await orderApi.states(query);
    setData(result);
  }

  useEffect(() => {
    if (isLoggedIn) fetchData();
  }, [isLoggedIn]);

  function handleSearchTypeChange(value) {
    setFilterType(value);
  }

  function handleFilterChange(value) {
    setFilter(value.trim().toLowerCase());
  }

  function handleSearch() {
    setData([]);
    const query = {};
    if (filter)
      switch (filterType) {
        case 1:
          query.partNumber = filter;
          break;
        case 2:
          query.id = filter;
          break;
        case 3:
          query.yourReference = filter;
          break;
        case 4:
          query.yourOrderId = filter;
          break;
      }
    fetchData(query);
  }

  function handleAllClick() {
    setFilter('');
    setData([]);
    fetchData();
  }

  const columns = useMemo(() => [
    { name: 'Order', width: 10 },
    { name: 'Date', width: 15 },
    { name: 'PartNumber', width: 15 },
    { name: 'Brand', width: 15 },
    { name: 'Booking', width: 10 },
    { name: 'Delivery', width: 10 },
    { name: 'Order Price', width: 10 },
    { name: 'Sale Price', width: 10 },
    { name: 'Currency', width: 10 },
    { name: 'InWork', width: 10 },
    { name: 'Ordered', width: 10 },
    { name: 'Confirmed', width: 10 },
    { name: 'To Dispatch', width: 10 },
    { name: 'Dispatched', width: 10 },
    { name: 'Refused', width: 10 },
    { name: 'Reference', width: 20 },
    { name: 'Description', width: 50 },
    { name: 'State Date', width: 15 },
    { name: 'SubstNumber', width: 15 },
    { name: 'SubstBrand', width: 15 },
  ]);

  const outputData = useMemo(
    () =>
      data.map((value) => [
        value.orderId,
        value.orderDate.slice(0, 10),
        value.partNumber,
        value.brand,
        value.catalog,
        `${value.delivery} (${value.tariff})`,
        value.priceOrder,
        value.priceSale,
        value.currency,
        value.inwork,
        value.ordered,
        value.purchased,
        value.accepted,
        value.shipped,
        value.refused,
        value.yourReference,
        value.answer,
        value.stateDate.slice(0, 16).replace('T', ' '),
        value.substNumber,
        value.substBrand,
      ]),
    [data]
  );

  async function handleToExcel() {
    await saveExcel('Orders', columns, outputData);
  }

  return (
    <Page>
      {!isLoggedIn && <Redirect to="/search" />}
      <ActionsGroup className={classes.actionsGroup}>
        <ActionsGroup className={classes.actionsGroup}>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={handleAllClick}
          >
            {t('all')}
          </Button>
          <SearchInputWithSelect
            className={classes.searchInput}
            selectItems={filterTypes}
            selectValue={filterType}
            searchValue={filter}
            onSelectChange={handleSearchTypeChange}
            onSearchClick={handleSearch}
            onSearchChange={handleFilterChange}
          />
        </ActionsGroup>
        <Button
          disabled={!data.length}
          classes={{ disabled: classes.disabledButton }}
          className={classes.excelButton}
          variant="contained"
          color="default"
          onClick={handleToExcel}
        >
          {t('export')}
        </Button>
      </ActionsGroup>
      <div className={classes.tableContainer}>
        <OrdersTable data={data} />
      </div>
    </Page>
  );
};

const styles = (theme) => {
  return {
    tableContainer: theme.tableContainer,
    actionsGroup: {
      justifyContent: 'space-between',
    },
    button: {},
    excelButton: {},
    searchInput: {
      marginLeft: theme.spacing.unit,
    },
  };
};

export default withStyles(styles)(OrdersPage);
